import $ from 'jquery';

export const outerHeight = (node: Node | undefined) => $(node as Node).outerHeight(true);
export const outerWidth = (node: Node | undefined) => $(node as Node).outerWidth(true);

export function setElementAttributes (element: Element, attributes: Record<string, string>) {
  Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
}

export function getGlobalCssVariableValue (variableName: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName);
}

export const nextMatching = (node: Node | undefined, selector: string): Node => $(node as Node).next(selector)[0];
export const previousMatching = (node: Node | undefined, selector: string): Node => $(node as Node).prev(selector)[0];

/**
 * Alternative to `Node.prototype.contains` that works with nodes in the Shadow DOM.
 *
 * @param node - The node for which to check whether it's contained
 * @param container - The container where the node should be contained in; by default the document node
 * @return Whether the node is contained in the container
 */
export function containsNode (node: Node, container: Node = document) {
  let currentNode: Node | null = node;
  while (currentNode != null) {
    if (currentNode === container) {
      return true;
    }
    currentNode = currentNode.parentNode instanceof ShadowRoot
      ? currentNode.parentNode.host
      : currentNode.parentNode;
  }
  return false;
}

/**
 * Alternative to `Element.prototype.closest` that works with elements in the Shadow DOM.
 *
 * @param node - The element for which to find the closest ancestor
 * @param selector - A selector to match the element and its ancestors against
 * @return The closest ancestor or the element itself, which matches the selector; If there is no such element, null
 */
export function closestElement<R extends Element = Element> (element: Element, selector: string) {
  let currentElement: Element | null = element;
  while (currentElement != null) {
    if (currentElement.matches(selector)) {
      return currentElement as R;
    }
    currentElement = currentElement.parentNode instanceof ShadowRoot
      ? currentElement.parentNode.host
      : currentElement.parentElement;
  }
  return null;
}
